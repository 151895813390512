import React, { Component } from 'react';
import { Button } from '@tigerpistol/react-core';
import StartPanel from './StartPanel';
import MarketingPanel from './MarketingPanel';
import AudiencePanel from './AudiencePanel';

export default class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            location: undefined
        };
    }

    onLocationChanged = (location) => {
        this.setState({ location });
    }

    onGetStartedClick = () => {
        this.props.onGetStarted(this.state.location);
    }

    onBackToTopClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { company } = this.props;
        const { startPanel, marketingPanel, audiencePanel } = company;

        return (
            <div className="landing-page fadein">
                {startPanel.enabled ? <StartPanel {...this.props} onGetStarted={this.onGetStartedClick} /> : null}
                {audiencePanel.enabled ? <AudiencePanel {...this.props} onGetStarted={this.onGetStartedClick} location={this.state.location} onLocationChanged={this.onLocationChanged} /> : null}
                {marketingPanel.enabled ? <MarketingPanel {...this.props} onGetStarted={this.onGetStartedClick} /> : null}
                <div className="footer">
                    <Button onClick={this.onBackToTopClick} id="back-to-top-btn">
                        <i className="fas fa-chevron-up" />
                    </Button>
                </div>
            </div>
        );
    }
}

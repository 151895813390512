let _uiStringsFile;
let _uiStringsFileOverrides;

export default {
    initialize: async (companyData) => {
        if (companyData.language) {
            try {
                // eslint-disable-next-line function-paren-newline
                const { default: moduleData } = await import(
                    /* webpackInclude: /uiStrings\.[a-z]{2}(-[A-Z][A-Z])?\.js$/ */
                    /* webpackChunkName: "uistrings" */
                    `./languages/uiStrings.${companyData.language}`
                );
                _uiStringsFile = moduleData;
            } catch (error) {
                console.error(`${companyData.language} is not a supported language`);
                const { default: englishData } = await import('./languages/uiStrings.en-US');
                _uiStringsFile = englishData;
            }
        }

        if (companyData.overrides) {
            _uiStringsFileOverrides = companyData.overrides;
        }
    },
    getUiStrings: () => _uiStringsFile,
    getString: (key) => {
        let result = '';

        if (_uiStringsFile && _uiStringsFile[key]) {
            result = _uiStringsFile[key];
        }

        // Using not null so that you can override with an empty string if you want to not display anything
        if (_uiStringsFileOverrides && _uiStringsFileOverrides[key] !== null && _uiStringsFileOverrides[key] !== undefined) {
            result = _uiStringsFileOverrides[key];
        }

        return result;
    }
};

import React from 'react';
import { Button } from '@tigerpistol/react-core';
import uiStrings from '../../services/uiStrings';
import './MarketingPanel.scss';

function renderVideoLinks(props) {
    const {
        marketingPanel,
        accentColor
    } = props.company;
    const { videoLinks } = marketingPanel;
    const style = { color: `#${accentColor}` };

    if (!videoLinks) return null;

    return videoLinks.length ?
        (
            <div>
                <h2>{uiStrings.getString('videos')}</h2>
                <div className="video-links">
                    {videoLinks.map((link, index) => {
                        const key = `${index}-${link.link}`;
                        return (
                            <a key={key} className="video-link" style={style} href={link.link} target="_blank" rel="noopener noreferrer">
                                <i className="far fa-play-circle" style={style} />
                                <span>{link.label}</span>
                            </a>
                        );
                    })}
                </div>
            </div>
        ) : null;
}

function renderInfoLinks(props) {
    const {
        marketingPanel,
        accentColor
    } = props.company;
    const { infoLinks } = marketingPanel;
    const style = { color: `#${accentColor}` };

    if (!infoLinks) return null;

    return infoLinks.length ?
        (
            <div>
                <h2>{uiStrings.getString('one_pagers')}</h2>
                <div className="info-links">
                    <ul>
                        {infoLinks.map((link, index) => {
                            const key = `${index}-${link.link}`;
                            return (<li key={key}><a className="info-link" style={style} href={link.link} target="_blank" rel="noopener noreferrer"><i className="fas fa-chalkboard-teacher" />{link.label}</a></li>);
                        })}
                    </ul>
                </div>
            </div>
        ) : null;
}

export default function MarketingPanel(props) {
    const { marketingPanel } = props.company;

    return (
        <section className="marketing-panel">
            <h1 className="section-title">{marketingPanel.heading || uiStrings.getString('marketing_panel_heading')}</h1>
            <p className="section-message">{marketingPanel.message || uiStrings.getString('marketing_panel_text')}</p>
            {renderVideoLinks(props)}
            {renderInfoLinks(props)}
            <Button className="cta-btn" onClick={props.onGetStarted} id="marketing-cta-btn">
                {uiStrings.getString('get_started')}
            </Button>
        </section>
    );
}

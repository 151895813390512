function toHex() {
    let ret = ((this < 0 ? 0x8 : 0) + ((this >> 28) & 0x7)).toString(16) + (this & 0xfffffff).toString(16);
    while (ret.length < 8) ret = `0${ret}`;
    return ret;
}

function quickHash(o, l = 2) {
    if (!o) {
        return undefined;
    }

    let i;
    let c;
    const r = [];

    for (i = 0; i < l; i++) {
        r.push(i * 268803292);
    }

    const objString = JSON.stringify(o);
    for (i = 0; i < objString.length; i++) {
        for (c = 0; c < r.length; c++) {
            r[c] = (r[c] << 13) - (r[c] >> 19);
            r[c] += objString.charCodeAt(i) << (r[c] % 24);
            // eslint-disable-next-line operator-assignment
            r[c] = r[c] & r[c];
        }
    }
    for (i = 0; i < r.length; i++) {
        r[i] = toHex.call(r[i]);
    }
    return r.join('');
}

function newUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

// Trim a specific character from the start and end of a string
function trim(str, char) {
    let output = str;
    if (str[0] === char) output = output.substr(1);
    if (str[str.length - 1] === char) output = output.slice(0, -1);

    return output;
}

export default {
    hash: quickHash,
    newUuid,
    trim
};

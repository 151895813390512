import React, { Component } from 'react';
import { Button } from '@tigerpistol/react-core';
import uiStrings from '../../services/uiStrings';
import api from '../../services/sutApi';
import './FacebookPageStep.scss';

export default class FacebookPageStep extends Component {
    onFacebookBtnClick = () => {
        api.openCustomerOnboarding(this.props.customer.id);
    }

    onFinishClick = () => {
        this.props.onNextStep(this.props.customer);
    }

    render() {
        const { facebookConnectPanel } = this.props.company;
        return (
            <section className="facebook-page-step">
                <h1 className="section-title">{facebookConnectPanel.heading || uiStrings.getString('facebook_connect_panel_heading')}</h1>
                <p className="section-message">{facebookConnectPanel.message || uiStrings.getString('facebook_connect_panel_text')}</p>
                <div>
                    <button type="button" id="connect-facebook-btn" className="text-btn" onClick={this.onFacebookBtnClick} aria-label="link facebook"><i className="fab fa-facebook-square" aria-hidden="true" /></button>
                </div>
                <Button onClick={this.onFinishClick} id="facebook-finish-btn">{uiStrings.getString('finish')}</Button>
            </section>
        );
    }
}

import Joi from 'joi';
import libphonenumber from 'google-libphonenumber';
import emailTlds from './languages/emailTlds';

// https://github.com/ruimarinho/google-libphonenumber
const _phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const _phoneUtilReasons = libphonenumber.PhoneNumberUtil.ValidationResult;
const _validationOptions = {
    abortEarly: false,
    allowUnknown: true,
    convert: false
};
let _extensions;

const initialize = async (uiStrings) => {
    _extensions = Joi
        .extend(joi => ({
            type: 'string',
            base: joi.string(),
            messages: {
                'string.empty': `{{#label}} ${uiStrings.required}.`,
                'phone.invalid': `{{#label}} ${uiStrings.joi_invalid}`,
                'phone.tooShort': `{{#label}} ${uiStrings.joi_too_short}`,
                'phone.tooLong': `{{#label}} ${uiStrings.joi_too_long}`,
                'phone.invalidCountry': `{{#label}} ${uiStrings.joi_invalid_country_code}`,
                'phone.invalidRegion': `{{#label}} ${uiStrings.joi_invalid_region} '{{#regionCode}}.'`,
                'phone.beginEndWhitespace': `{{#label}} ${uiStrings.joi_begin_end_whitespace}`
            },
            rules: {
                phone: {
                    method(regionCode) {
                        return this.$_addRule({ name: 'phone', args: { regionCode } });
                    },
                    args: [
                        {
                            name: 'regionCode',
                            ref: true,
                            assert: value => typeof value === 'string',
                            message: 'must be a string'
                        }
                    ],
                    validate(value, helpers, args) {
                        let error;

                        if (!value) return helpers.error('string.empty', { regionCode: '""' });

                        if (/^\s+|\s+$/.test(value)) return helpers.error('phone.beginEndWhitespace');

                        try {
                            const { regionCode } = args;
                            if (!regionCode) return helpers.error('phone.invalidCountry', { regionCode: '""' });

                            const parsedNumber = _phoneUtil.parseAndKeepRawInput(value, regionCode);
                            const isValid = _phoneUtil.isValidNumberForRegion(parsedNumber, regionCode);
                            if (!isValid) {
                                const reason = _phoneUtil.isPossibleNumberWithReason(parsedNumber);
                                switch (reason) {
                                    case _phoneUtilReasons.TOO_SHORT:
                                        error = helpers.error('phone.tooShort', {});
                                        break;
                                    case _phoneUtilReasons.TOO_LONG:
                                        error = helpers.error('phone.tooLong', {});
                                        break;
                                    case _phoneUtilReasons.INVALID_COUNTRY_CODE:
                                        error = helpers.error('phone.invalidCountry', { regionCode });
                                        break;
                                    default:
                                        error = helpers.error('phone.invalidRegion', { regionCode });
                                }
                            }
                        } catch (e) {
                            error = helpers.error('phone.invalid');
                        }

                        return !error ? value : error;
                    }
                }
            }
        }))
        .extend(joi => ({
            type: 'email',
            base: joi.string()
                .ruleset
                .email({ minDomainSegments: 2, tlds: { allow: emailTlds } })
                .rule({ message: `${uiStrings.contact_email} ${uiStrings.joi_invalid}` })
                .trim()
                .rule({ message: `${uiStrings.contact_email} ${uiStrings.joi_begin_end_whitespace}` })
        }))
        .extend(joi => ({
            type: 'uri',
            base: joi.string()
                .ruleset
                .uri({ scheme: /https?/ })
                .rule({ message: `${uiStrings.website} ${uiStrings.joi_invalid} "http(s)://" ${uiStrings.required}.` })
        }))
        .extend(joi => ({
            type: 'require',
            base: joi.string()
                .trim()
                .required()
                .messages({
                    'string.empty': `{{#label}} ${uiStrings.required}.`,
                    'string.trim': `{{#label}} ${uiStrings.joi_begin_end_whitespace}`,
                    'any.required': `{{#label}} ${uiStrings.required}.`
                })
        }));
};

const validate = (schema, formData) => {
    const errors = {};
    const properSchema = Joi.isSchema(schema) ? schema : Joi.extensions.object(schema);
    const { error } = properSchema.validate(formData, _validationOptions);

    if (error && error.details && error.details.length) {
        for (let i = 0; i < error.details.length; i++) {
            const item = error.details[i];

            if (item.path[0]) {
                errors[item.path[0]] = item.message;
            } else if (item.context.peers.length) {
                item.context.peers.forEach((peer) => { errors[peer] = item.message; });
            }
        }
    }

    return Object.keys(errors).length === 0 ? null : errors;
};

export default {
    initialize,
    validate,
    getExtensions: () => _extensions
};

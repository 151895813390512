import React, { Component } from 'react';
import { ThemeContext } from '@tigerpistol/react-core';
import api from './services/sutApi';
import uiStrings from './services/uiStrings';
import joi from './services/joi';
import googleTagManager from './services/googleTagManager';
import './App.scss';
import LogoHeader from './components/LogoHeader';
import LandingPage from './components/landingPage/LandingPage';
import SignUpPage from './components/signUpPage/SignUpPage';
import GdprCookieNotice from './components/GdprCookieNotice';
import PrivacyPolicy from './components/PrivacyPolicy';

function setHasStarted(stateUpdates) {
    this.setState(stateUpdates);
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: null,
            defaultAddress: null,
            isLoading: true,
            hasStarted: false,
            hasGoogleTagManagerIntegration: false,
            privacyPolicyLink: null,
            footerMessage: null
        };
    }

    componentDidMount() {
        api.getCompanyDetails()
            .then(async (companyData) => {
                const state = { ...this.state };
                state.company = companyData;
                if (companyData) {
                    document.title = companyData.name;
                    await uiStrings.initialize(companyData);
                    await joi.initialize(uiStrings.getUiStrings());
                }

                if (companyData && companyData.googleTagManagerId) {
                    state.hasGoogleTagManagerIntegration = true;
                    googleTagManager.applyIntegration(companyData.googleTagManagerId);
                }
                state.isLoading = false;
                this.setState(state);
            })
            .catch(() => {
                this.setState({ isLoading: false });
            });

        api.getPrivacyPolicy()
            .then((link) => {
                this.setState({ privacyPolicyLink: link });
            });

        api.getFooterMessage()
            .then((message) => {
                this.setState({ footerMessage: message });
            });
    }

    onGetStarted = (location) => {
        setHasStarted.call(this, { hasStarted: true, defaultAddress: location });
    };

    onStartOver = setHasStarted.bind(this, { hasStarted: false });

    // Based on: https://stackoverflow.com/a/28056903
    muteHexColor = (hex, opacity) => {
        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);
        return `rgba(${r},${g},${b},${opacity})`;
    }

    buildPartnerStyles = accentColor => `
        .pulsating-circle:before { background-color: #${accentColor}; }
        .App .landing-page section .section-title:after { border-bottom-color: #${accentColor}; }
        .react-autosuggest__container .react-autosuggest__suggestions-list li.react-autosuggest__suggestion--highlighted { background-color: ${this.muteHexColor(accentColor, 0.3)}}
    `;

    renderCompanyContentElement = () => {
        const {
            company,
            hasStarted,
            defaultAddress,
            hasGoogleTagManagerIntegration,
            privacyPolicyLink
        } = this.state;

        return !hasStarted ?
            <LandingPage company={company} onGetStarted={this.onGetStarted} /> :
            <SignUpPage company={company} defaultAddress={defaultAddress} hasGoogleTagManagerIntegration={hasGoogleTagManagerIntegration} privacyPolicyLink={privacyPolicyLink} onStartOver={this.onStartOver} />;
    }

    renderCompanyNotFoundElement = () => (
        <section>
            <h1>{uiStrings.getString('page_not_found')}</h1>
        </section>
    )

    renderLoadingElement = () => (
        <section className="mock start-panel">
            <h1 className="mock section-title">{uiStrings.getString('page_loading')}</h1>
            <p className="mock section-message pulse" />
            <div className="mock start-panel-content">
                <div className="mock marketing-image pulse" />
                <div>
                    <p>{uiStrings.getString('loading_message')}</p>
                    <div className="mock-btn pulse" />
                </div>
            </div>
        </section>
    )

    render() {
        const {
            isLoading,
            company,
            hasStarted,
            hasGoogleTagManagerIntegration
        } = this.state;

        let element;
        if (isLoading) {
            element = this.renderLoadingElement();
        } else if (company !== null) {
            element = this.renderCompanyContentElement();
        } else {
            element = this.renderCompanyNotFoundElement();
        }

        const partnerStyles = company ? this.buildPartnerStyles(company.accentColor) : null;
        const context = company ? { accentColor: company.accentColor } : {};

        return (
            <ThemeContext.Provider value={context}>
                <div className={`App ${hasStarted ? 'started' : 'landing'}`}>
                    <style>{partnerStyles}</style>
                    <LogoHeader company={company} isLoading={isLoading} />
                    {element}
                    <PrivacyPolicy {...this.state} />
                </div>
                {!hasGoogleTagManagerIntegration &&
                    <GdprCookieNotice privacyPolicyLink={this.state.privacyPolicyLink} />
                }
            </ThemeContext.Provider>
        );
    }
}

App.contextType = ThemeContext;

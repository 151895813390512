import React from 'react';
import { Button } from '@tigerpistol/react-core';
import uiStrings from '../../services/uiStrings';
import './StartPanel.scss';

export default function StartPanel(props) {
    const { company } = props;
    const { startPanel } = company;

    return (
        <section className="start-panel">
            <h1 className="section-title">{startPanel.heading || uiStrings.getString('start_panel_heading')}</h1>
            <p className="section-message">{startPanel.message || uiStrings.getString('start_panel_text')}</p>
            <div className="start-panel-content">
                {startPanel.imageUrl ? <img src={startPanel.imageUrl} className="marketing-image" alt="Marketing" /> : null }
                <div>
                    <p>{uiStrings.getString('startpanel_ready_message')}</p>
                    <Button className="cta-btn" onClick={props.onGetStarted} id="start-cta-btn">
                        {uiStrings.getString('get_started')}
                    </Button>
                </div>
            </div>
        </section>
    );
}

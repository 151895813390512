import React from 'react';
import uiStrings from '../services/uiStrings';

export default function PrivacyPolicy(props) {
    return (
        <div className="privacy-policy">
            <h6>
                {props.footerMessage} &nbsp; | &nbsp;
                <a
                    href={props.privacyPolicyLink}
                    id="privacy-policy-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'rgb(47, 205, 253)' }}
                >
                    {uiStrings.getString('privacy_policy')}
                </a>
            </h6>
        </div>
    );
}

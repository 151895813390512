import React, { useState, useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Button, ThemeContext } from '@tigerpistol/react-core';
import FormattedMessage from './FormattedMessage';
import uiStrings from '../services/uiStrings';
import './GdprCookieNotice.scss';

const root = document.getElementById('gdpr-root');

export default function GdprCookieNotice({ privacyPolicyLink }) {
    const storageKey = `gdpr-cookie-notice-${window.location.hostname}`;
    const [show, setShow] = useState(() => !localStorage[storageKey]);
    const { accentColor } = useContext(ThemeContext);

    const handleAcceptClick = useCallback(() => {
        localStorage[storageKey] = true;
        setShow(false);
    }, [storageKey]);

    return (show &&
        ReactDOM.createPortal(
            <div className="gdpr-cookie-notice">
                <p>
                    <FormattedMessage
                        message={uiStrings.getString('gdpr_cookie_notice')}
                        fields={[
                            <a
                                href={privacyPolicyLink}
                                id="privacy-policy-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: `#${accentColor}` }}
                            >
                                {uiStrings.getString('privacy_policy')}
                            </a>
                        ]}
                    />
                    <Button onClick={handleAcceptClick}>{uiStrings.getString('accept')}</Button>
                </p>
            </div>,
            root
        )
    );
}

export default function FormattedMessage({ message, fields }) {
    const output = [];
    let accumulator = '';
    for (let i = 0; i < message.length; i++) {
        if (message[i] === '{') {
            output.push(accumulator);
            accumulator = '';

            const index = Number(message[++i]);
            output.push(fields[index]);
            i++;
        } else {
            accumulator += message[i];
        }
    }

    if (accumulator) output.push(accumulator);

    return output;
}

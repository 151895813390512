import React from 'react';
import ReactDOM from 'react-dom';
import { HereMapsApi } from '@tigerpistol/react-core';
import './services/logger';
import './styles/index.scss';
import App from './App';

HereMapsApi.initMapApi('xiguuVpBfr7NbsOQPHCw', 't5rG2EzkXtAML3D3ql6MPA');

ReactDOM.render(<App />, document.getElementById('root'));

import React, { Component } from 'react';
import {
    Button, SelectInput, LocationInput, HereMap
} from '@tigerpistol/react-core';
import uiStrings from '../../services/uiStrings';
import api from '../../services/sutApi';
import './AudiencePanel.scss';

const range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);

export default class AudiencePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            radius: 10,
            reach: undefined,
            dropPin: false,
            calculatingReach: false
        };
    }

    onDropPinClick = () => this.setState({ dropPin: true });

    onPinDropped = (location) => { this.onLocationInputChanged({}, { newValue: location }); }

    onLocationInputChanged = async (event, { newValue }) => {
        const location = newValue;
        const { company } = this.props;
        const { radius } = this.state;
        const { radiusUnits } = company;

        if (!location) {
            this.setState({ dropPin: false, reach: null });
            this.props.onLocationChanged(location);
            return;
        }

        let streetOverride = false;
        if (location.type === 'street') {
            streetOverride = true;
            location.type = 'address';
        }

        location.radiusUnits = radiusUnits;
        location.radius = radius;

        // Updating now so the the pin shows up immediately.
        this.setState({ dropPin: false, calculatingReach: true });
        this.props.onLocationChanged(location);

        try {
            const estimate = await api.getReachEstimate(location);
            const { users } = estimate;

            this.setState({ reach: users, calculatingReach: false });

            if (streetOverride) {
                location.type = 'street'; // the next screen will handle further address validation
                this.props.onLocationChanged(location);
            }
        } catch (err) {
            this.setState({ calculatingReach: false });
        }
    };

    onRadiusChange = async (event) => {
        const newValue = event.target.value;
        const { location } = this.props;

        // Updating now so the the pin shows up immediately.
        this.setState({ radius: +newValue, calculatingReach: !!location });

        if (location) {
            location.radius = +newValue;

            this.props.onLocationChanged(location);

            const estimate = await api.getReachEstimate(location);
            this.setState({ reach: estimate.users, calculatingReach: false });
        }
    };

    getRadiusOptions = () => {
        const { location } = this.props;
        const isMetric = this.props.company.radiusUnits === 'miles';
        const max = isMetric ? 50 : 80;
        let min = 1;
        if (location && location.type === 'city') min = isMetric ? 10 : 16;
        return range(min, max);
    };

    renderReachEstimate = () => {
        const { reach, calculatingReach } = this.state;

        if (calculatingReach) {
            return (
                <div id="reach-estimate">
                    <i className="pulsating-circle for-reach" />
                </div>
            );
        } else if (reach) {
            return (
                <div id="reach-estimate">
                    <i className="fas fa-5x fa-user-circle" />
                    <p>{uiStrings.getString('your_potential_reach')}</p>
                    <h1>{reach.toLocaleString()} {uiStrings.getString('users')}</h1>
                </div>
            );
        }

        return null;
    };

    render() {
        const { company, location } = this.props;
        const { audiencePanel, radiusUnits, defaultMapPosition } = company;
        const { radius, dropPin } = this.state;
        const radiusOptions = this.getRadiusOptions();
        let center;
        if (defaultMapPosition && defaultMapPosition.latitude && defaultMapPosition.longitude) {
            center = { lat: defaultMapPosition.latitude, lng: defaultMapPosition.longitude };
        }

        const locations = [];
        if (location) {
            locations.push(location);
        }

        return (
            <section className="audience-panel">
                <h1 className="section-title">{audiencePanel.heading || uiStrings.getString('audience_panel_heading')}</h1>
                <p className="section-message">{audiencePanel.message || uiStrings.getString('audience_panel_text')}</p>
                <div>
                    <LocationInput
                        placeholder={uiStrings.getString('business_location')}
                        id="business-location-input"
                        value={location}
                        onChange={this.onLocationInputChanged}
                        matchTypes={['address', 'street']}
                        showLatLong
                    />
                    <div className="row">
                        <SelectInput
                            groupClassName="radius-input"
                            id="radius-input"
                            placeholder={uiStrings.getString('radius')}
                            value={radius}
                            options={radiusOptions}
                            renderOption={value => `${value} ${this.props.company.radiusUnits}`}
                            onChange={this.onRadiusChange}
                        />
                        <Button className="pin-btn" onClick={this.onDropPinClick} disabled={dropPin} id="audience-pin-btn">
                            <i className="fas fa-map-marker-alt" />&nbsp;{uiStrings.getString('drop_pin')}
                        </Button>
                    </div>
                    <HereMap waitingDrop={dropPin} onPinDrop={this.onPinDropped} locations={locations} units={radiusUnits} center={center} />
                    {this.renderReachEstimate()}
                </div>
                <Button className="cta-btn" onClick={this.props.onGetStarted} id="audience-cta-btn">
                    {uiStrings.getString('get_started')}
                </Button>
            </section>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import uiStrings from '../../services/uiStrings';
import './SubmittingCustomerLoader.scss';

// Inspired by Vitaly Silkin's Dribbble shot:
// https://dribbble.com/shots/3907093-Escalade-loader
const SubmittingCustomerLoader = (props) => {
    const { topColor, bottomColor } = props;
    return (
        <div className="overlay submitting-customer">
            <h1>{uiStrings.getString('creating_your_account')}</h1>
            <p><i>{uiStrings.getString('this_will_only_take_a_moment')}</i></p>
            <svg>
                <g>
                    <path d="M 50,100 A 1,1 0 0 1 50,0" />
                </g>
                <g>
                    <path d="M 50,75 A 1,1 0 0 0 50,-25" />
                </g>
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" style={{ stopColor: `#${topColor}`, stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: `#${bottomColor}`, stopOpacity: 1 }} />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

SubmittingCustomerLoader.propTypes = {
    topColor: PropTypes.string,
    bottomColor: PropTypes.string
};

SubmittingCustomerLoader.defaultProps = {
    topColor: 'FF56A1',
    bottomColor: 'FF9350'
};

export default SubmittingCustomerLoader;

import React from 'react';

export default function LogoHeader(props) {
    const { company, isLoading } = props;

    let logoHeaderStyle = {};
    let content;
    if (isLoading) {
        content = <i className="fas fa-spin fa-6x fa-compass" />;
    } else if (company !== null) {
        logoHeaderStyle = { backgroundColor: `#${company.navHeaderColor}` };
        content = <img className="partner-logo" src={company.logoUrl} alt="company logo" />;
    } else {
        content = <i className="far fa-6x fa-grimace" />;
    }

    return (
        <div className="logo-header" style={logoHeaderStyle}>
            {content}
        </div>
    );
}

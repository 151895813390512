function insertHeadScript(googleTagManagerId) {
    const headScriptStartElement = document.createComment(' Google Tag Manager ');
    document.head.appendChild(headScriptStartElement);

    const headScriptElement = document.createElement('script');
    const headScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagManagerId}');`;
    headScriptElement.innerHTML = headScript;
    document.head.appendChild(headScriptElement);

    const headScriptEndElement = document.createComment(' End Google Tag Manager ');
    document.head.appendChild(headScriptEndElement);
}

function insertBodyElements(googleTagManagerId) {
    const bodyScriptStartElement = document.createComment(' Google Tag Manager (noscript) ');
    document.body.appendChild(bodyScriptStartElement);

    const bodyNoScriptElement = document.createElement('noscript');
    const bodyiFrameElement = document.createElement('iframe');

    bodyiFrameElement.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`;
    bodyiFrameElement.height = 0;
    bodyiFrameElement.width = 0;
    bodyiFrameElement.style = 'display:none;visibility:hidden';
    bodyNoScriptElement.appendChild(bodyiFrameElement);
    document.body.appendChild(bodyNoScriptElement);

    const bodyScriptEndElement = document.createComment(' End Google Tag Manager (noscript) ');
    document.body.appendChild(bodyScriptEndElement);
}

function applyIntegration(googleTagManagerId) {
    insertHeadScript(googleTagManagerId);
    insertBodyElements(googleTagManagerId);
}

export default {
    applyIntegration
};

import React, { Component } from 'react';
import { Button, TextInput } from '@tigerpistol/react-core';
import uiStrings from '../../services/uiStrings';
import api from '../../services/sutApi';
import './FinishStep.scss';

export default class FinishStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            isSendingEmail: false
        };
    }

    onEmailAddressChange = (event, { newValue }) => {
        this.setState({ email: newValue });
    };

    onSendEmailClick = async () => {
        this.setState({ isSendingEmail: true });
        const customer = JSON.parse(JSON.stringify(this.props.customer));
        customer.email = this.state.email;

        await api.sendEmail(customer);
        this.setState({ isSendingEmail: false });
    };

    onPDFDownloadClick = () => {
        api.downloadPdfRecord(this.props.customer);
    };

    renderIcon = color => (
        <svg xmlns="http://www.w3.org/2000/svg" height="140pt" opacity="1" version="1.1" viewBox="0 0 511 511.99956" width="140pt">
            <g id="surface1">
                <path
                    d="m399.20938,255.50051l-140.70939,-252.58595l253.00001,252.58595l-253.00001,252.58581l140.70939,-252.58581z"
                    strokeWidth="null"
                    fill={`#${color}`}
                    fillOpacity="1"
                />
                <path
                    transform="rotate(179.7897491455078 126,256.5004272460937)"
                    d="m140.20938,256.50049l-140.70939,-252.58595l253.00001,252.58595l-253.00001,252.58581l140.70939,-252.58581z"
                    strokeWidth="null"
                    fill={`#${color}`}
                    fillOpacity="1"
                />
                <path
                    d="m1,256l255,-255l255,255l-255,255l-255,-255z"
                    strokeWidth="5"
                    fillRule="nonzero"
                    fill={`#${color}`}
                    fillOpacity="0.8"
                />

                <path
                    fillRule="nonzero"
                    fill="rgb(100%,100%,100%)"
                    d="m384.10156,253c0,70.20703 -57.0039,127.35547 -127.15234,127.60547l-0.44922,0c-70.35547,0 -127.60547,-57.24609 -127.60547,-127.60547c0,-70.35547 57.25,-127.60156 127.60547,-127.60156l0.44922,0c70.14844,0.25 127.15234,57.39844 127.15234,127.60156zm0,0"
                />
                <path
                    fillRule="nonzero"
                    fill="rgb(70.588235%,82.352941%,84.313725%)"
                    d="m314.41797,234.625l-57.46875,57.47656l-12.41797,12.41797c-2.93359,2.9336 -6.78125,4.39844 -10.625,4.39844c-3.84766,0 -7.69141,-1.46484 -10.62891,-4.39844l-26.69921,-26.71094c-5.86719,-5.86718 -5.86719,-15.37109 0,-21.24218c5.85937,-5.86719 15.375,-5.86719 21.24218,0l16.08594,16.08593l59.26953,-59.26953c5.86719,-5.87109 15.38281,-5.87109 21.24219,0c5.86719,5.86719 5.86719,15.38282 0,21.24219zm0,0"
                />
            </g>
        </svg>
    );

    render() {
        const { company, customer } = this.props;
        const { email, isSendingEmail } = this.state;
        const { finishPanel } = company;

        return (
            <section className="finish-step">
                <h1 className="section-title">{finishPanel.heading || uiStrings.getString('finish_panel_heading')}</h1>
                <p className="section-message">{finishPanel.message || uiStrings.getString('finish_panel_text')}</p>
                <div className="details-container">
                    {this.renderIcon(company.accentColor)}
                    <div className="details-box">
                        <p id="business-name-text"><b>{uiStrings.getString('business_name')}:</b> {customer.businessName}</p>
                        <p id="business-id-text"><b>{uiStrings.getString('business_id')}:</b> {customer.externalId}</p>
                        <p id="record-id-text"><b>{uiStrings.getString('record_id')}:</b> {customer.id}</p>
                    </div>
                </div>
                <div className="inputs-container">
                    <Button id="finish-pdf-btn" onClick={this.onPDFDownloadClick} loading={isSendingEmail}>
                        <i className="fas fa-download" /> {uiStrings.getString('download_pdf_record')}
                    </Button>
                    <div className="input-group">
                        <TextInput
                            id="email-input"
                            name="email"
                            value={email}
                            placeholder={uiStrings.getString('contact_email')}
                            onChange={this.onEmailAddressChange}
                        />
                        <Button
                            id="finish-send-btn"
                            className="input-addon-right"
                            onClick={this.onSendEmailClick}
                            disabled={!email}
                            loading={isSendingEmail}
                        >
                            {uiStrings.getString('send')}
                        </Button>
                    </div>
                </div>
            </section>
        );
    }
}

import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import utils from './utils';

// Custom formatter to parse out the data objects from the message
export function formatter(content) {
    const arr = content.message.split('__data__');

    const msgs = [];
    let data = {};
    arr.forEach((str) => {
        try {
            const parsed = JSON.parse(str);
            data = { ...data, ...parsed };
        } catch (e) {
            const cleaned = utils.trim(str, ',');
            if (cleaned) msgs.push(cleaned);
        }
    });

    return {
        ...content,
        level: content.level.label,
        message: msgs.join(' '),
        data: JSON.stringify(data)
    };
}

// Apply the loglevel-remote plugin
remote.apply(log, { format: formatter, url: '/api/logger' });

// Custom plugin to format the message so we can later parse out the data objects
const originalFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName) => {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);
    return (...args) => {
        const values = args.map((arg) => {
            if (typeof arg === 'object') {
                return `__data__${JSON.stringify(arg)}__data__`;
            }

            return arg;
        });
        rawMethod(values);
    };
};

log.enableAll();

export default log;

import React, { Component } from 'react';
import log from 'loglevel';
import {
    Button, Utils, TextInput, SelectInput, LocationInput, HereMap, CheckBox, ThemeContext
} from '@tigerpistol/react-core';
import SubmittingCustomerLoader from './SubmittingCustomerLoader';
import FormattedMessage from '../FormattedMessage';
import uiStrings from '../../services/uiStrings';
import api from '../../services/sutApi';
import utils from '../../services/utils';
import joi from '../../services/joi';
import './BusinessDetailsStep.scss';

const generateSchema = regionCode => joi.getExtensions().object({
    businessName: joi.getExtensions().require().label(uiStrings.getString('business_name')),
    externalId: joi.getExtensions().require().label(uiStrings.getString('business_id')),
    firstName: joi.getExtensions().require().label(uiStrings.getString('contact_first_name')),
    lastName: joi.getExtensions().require().label(uiStrings.getString('contact_last_name')),
    email: joi.getExtensions()
        .email()
        .label(uiStrings.getString('contact_email')),
    phone: joi.getExtensions().string()
        .phone(regionCode)
        .label(uiStrings.getString('contact_phone')),
    website: joi.getExtensions()
        .uri()
        .allow('')
        .label(uiStrings.getString('website')),
    address: joi.getExtensions()
        .require()
        .label(uiStrings.getString('business_location'))
});

const getDefaultLanguage = company => (company.language === 'pt-PT' ? 'pt-BR' : company.language);

const renderSumbitting = accentColor => (
    <SubmittingCustomerLoader bottomColor={accentColor} topColor={accentColor} />
);

export default class BusinessDetailsStep extends Component {
    submit = Utils.debounce(async () => {
        this.setState({ submitting: true, errorMessage: null });
        const customer = { ...this.state.customer };

        const addressType = customer && customer.address && customer.address.type;
        const hasLatLong = customer && customer.address && !!customer.address.latitude && !!customer.address.longitude;
        if (addressType === 'street' && !hasLatLong) {
            this.setState({ submitting: false, errorMessage: uiStrings.getString('street_address_type_error') });
            return;
        }
        if (!this.state.validAddress || (addressType !== 'address' && !hasLatLong)) {
            this.setState({ submitting: false, errorMessage: uiStrings.getString('error_address_invalid') });
            return;
        }

        const regionCode = customer.address ? customer.address.countryCode : '';
        const address = customer.address ? customer.address.address : '';
        const errors = joi.validate(generateSchema(regionCode), { ...customer, address });
        if (errors) {
            this.setState({ submitting: false, errorMessage: errors[Object.keys(errors)[0]], validationErrors: errors });
            return;
        }

        try {
            log.info('Customer Create clicked', { hash: utils.hash(customer) });
            const toSubmit = {
                ...customer,
                address: {
                    ...customer.address.structuredAddress,
                    latitude: customer.address.latitude,
                    longitude: customer.address.longitude
                }
            };
            const customerId = await api.createCustomer(toSubmit);
            toSubmit.id = customerId;
            this.props.onNextStep(toSubmit);
        } catch (err) {
            log.info('Customer Create errored', { err });
            this.setState({ submitting: false, errorMessage: err.message });
        }
    }, 2000, true)

    constructor(props) {
        super(props);

        const { company, defaultAddress, hasGoogleTagManagerIntegration } = this.props;
        const streetAddress = (defaultAddress && defaultAddress.type) === 'street';
        const hasLatLong = defaultAddress && !!defaultAddress.latitude && !!defaultAddress.longitude;
        const validAddress = (defaultAddress && defaultAddress.type) === 'address' || hasLatLong;

        let errorMessage = '';
        let waitingPinDrop = false;
        if (defaultAddress && !hasLatLong) {
            if (streetAddress) {
                errorMessage = uiStrings.getString('street_address_type_error');
                waitingPinDrop = true;
            } else if (!validAddress) uiStrings.getString('error_address_invalid');
        }

        this.state = {
            customer: {
                businessName: '',
                externalId: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                language: getDefaultLanguage(company),
                address: defaultAddress,
                website: ''
            },
            consentGiven: hasGoogleTagManagerIntegration,
            waitingPinDrop,
            validAddress,
            submitting: false,
            errorMessage,
            validationErrors: {}
        };
    }

    onDropPinClick = () => this.setState({ waitingPinDrop: true });

    onPinDropped = (address) => {
        this.setState((prevState) => {
            const { customer, errorMessage } = prevState;
            const errorAddressInvalid = uiStrings.getString('error_address_invalid');
            const streetAddressError = uiStrings.getString('street_address_type_error');
            const hasLatLong = !!address.latitude && !!address.longitude;

            customer.address = address;

            let newErr;
            let waitingPinDrop = false;
            // Case 0: location is street type and we want a tailored error message
            if ((address && address.type) === 'street' && !hasLatLong) {
                waitingPinDrop = true;
                newErr = streetAddressError;

                // Case 1: location is not an address, so display the "invalid address" error
            } else if ((address && address.type) !== 'address' && !hasLatLong) newErr = errorAddressInvalid;
            // Case 2: location *is* an address and page currently displays "invalid address" error, so clear the error message
            else if (errorMessage === errorAddressInvalid || errorMessage === streetAddressError) newErr = '';
            // Case 3: location *is* an address and error is something else. So leave the error message unchanged.
            else newErr = errorMessage;

            return {
                customer,
                errorMessage: newErr,
                waitingPinDrop,
                validAddress: true
            };
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.submit();
    }

    setValidFlag = (setVal) => {
        this.setState({
            validAddress: setVal
        });
    }

    handleInputChange = (event, { newValue }) => {
        const { target } = event;
        const value = (target && target.type === 'checkbox') ? target.checked : newValue;
        const name = (target && target.name) || 'address';

        this.setState((prevState) => {
            const { customer } = prevState;

            customer[name] = value;
            return { customer };
        });
    }

    handleLocationInputChange = (event, { newValue: address }) => {
        this.setState((prevState) => {
            const { customer, errorMessage } = prevState;
            const errorAddressInvalid = uiStrings.getString('error_address_invalid');
            const streetAddressError = uiStrings.getString('street_address_type_error');
            const hasLatLong = !!address && !!address.latitude && !!address.longitude;

            let newErr;
            let waitingPinDrop = false;
            // Case 0: location is street type and we want a tailored error message
            if ((address && address.type) === 'street' && !hasLatLong) {
                waitingPinDrop = true;
                newErr = streetAddressError;

                // Case 1: location is not an address, so display the "invalid address" error
            } else if ((address && address.type) !== 'address' && !hasLatLong) newErr = errorAddressInvalid;
            // Case 2: location *is* an address and page currently displays "invalid address" error, so clear the error message
            else if (errorMessage === errorAddressInvalid || errorMessage === streetAddressError) newErr = '';
            // Case 3: location *is* an address and error is something else. So leave the error message unchanged.
            else newErr = errorMessage;

            customer.address = address;
            return { customer, errorMessage: newErr, waitingPinDrop };
        });
    }

    handleConsentGivenChange = (event, { newValue }) => {
        this.setState({ consentGiven: newValue });
    }

    renderPinContainerContent() {
        const { radiusUnits, defaultMapPosition } = this.props.company;
        const { waitingPinDrop, customer } = this.state;
        let center;
        if (defaultMapPosition && defaultMapPosition.latitude && defaultMapPosition.longitude) {
            center = { lat: defaultMapPosition.latitude, lng: defaultMapPosition.longitude };
        }

        if (!waitingPinDrop) {
            return (
                <Button className="pin-btn" small onClick={this.onDropPinClick} id="campaign-details-pin-btn">
                    <i className="fas fa-map-marker-alt" />&nbsp;{uiStrings.getString('drop_pin')}
                </Button>
            );
        }

        const locations = [];
        if (customer.address) {
            locations.push(customer.address);
        }

        const closeFunc = () => this.setState({ waitingPinDrop: false });
        return (
            <React.Fragment>
                <Button className="close-btn" small onClick={closeFunc} id="campaign-details-pin-close-btn">
                    <i className="fas fa-times" />
                </Button>,
                <HereMap waitingDrop={waitingPinDrop} interactionsEnabled onPinDrop={this.onPinDropped} locations={locations} units={radiusUnits} center={center} zoom={customer.address ? 17 : 9} />
            </React.Fragment>
        );
    }

    renderForm() {
        const { company, hasGoogleTagManagerIntegration, privacyPolicyLink } = this.props;
        const { supportedLanguages, businessDetailsPanel } = company;
        const {
            waitingPinDrop,
            customer,
            errorMessage,
            consentGiven
        } = this.state;

        return (
            <React.Fragment>
                <h1 className="section-title">{businessDetailsPanel.heading !== null ? businessDetailsPanel.heading : uiStrings.getString('business_details_panel_heading')}</h1>
                <p className="section-message">{businessDetailsPanel.message !== null ? businessDetailsPanel.message : uiStrings.getString('business_details_panel_text')}</p>
                {errorMessage ? <h4 className="alert red">{errorMessage}</h4> : null}
                <form onSubmit={this.onSubmit}>
                    <TextInput
                        id="business-name-input"
                        name="businessName"
                        value={customer.businessName}
                        placeholder={uiStrings.getString('business_name')}
                        onChange={this.handleInputChange}
                        error={this.state.validationErrors.businessName}
                    />
                    <TextInput
                        id="external-id-input"
                        name="externalId"
                        value={customer.externalId}
                        placeholder={uiStrings.getString('business_id')}
                        onChange={this.handleInputChange}
                        error={this.state.validationErrors.externalId}
                    />
                    <TextInput
                        id="first-name-input"
                        name="firstName"
                        value={customer.firstName}
                        placeholder={uiStrings.getString('contact_first_name')}
                        onChange={this.handleInputChange}
                        error={this.state.validationErrors.firstName}
                    />
                    <TextInput
                        id="last-name-input"
                        name="lastName"
                        value={customer.lastName}
                        placeholder={uiStrings.getString('contact_last_name')}
                        onChange={this.handleInputChange}
                        error={this.state.validationErrors.lastName}
                    />
                    <TextInput
                        id="email-input"
                        name="email"
                        value={customer.email}
                        placeholder={uiStrings.getString('contact_email')}
                        onChange={this.handleInputChange}
                        error={this.state.validationErrors.email}
                    />
                    <TextInput
                        id="phone-input"
                        name="phone"
                        value={customer.phone}
                        placeholder={uiStrings.getString('contact_phone')}
                        onChange={this.handleInputChange}
                        error={this.state.validationErrors.phone}
                    />
                    <TextInput
                        id="website-input"
                        name="website"
                        value={customer.website}
                        onChange={this.handleInputChange}
                        placeholder={uiStrings.getString('website')}
                        error={this.state.validationErrors.website}
                    />
                    <SelectInput
                        placeholder={uiStrings.getString('preferred_language')}
                        id="preferred-language-select"
                        value={customer.language}
                        options={supportedLanguages}
                        renderOption={value => value.label}
                        getOptionValue={value => value.value}
                        name="language"
                        onChange={this.handleInputChange}
                        error={this.state.validationErrors.language}
                    />
                    <LocationInput
                        placeholder={uiStrings.getString('business_location')}
                        id="business-location-input"
                        value={customer.address}
                        onChange={this.handleLocationInputChange}
                        setValidFlag={this.setValidFlag}
                        matchTypes={['address', 'street']}
                        error={this.state.validationErrors.address}
                        showLatLong
                    />
                    <div className={`map-pin-container ${waitingPinDrop ? 'pin' : ''}`}>
                        {this.renderPinContainerContent()}
                    </div>
                    <div className="submit-btn-container">
                        {!hasGoogleTagManagerIntegration && (
                            <div className="gdpr-consent-container">
                                <div>{uiStrings.getString('gdpr_collection_info_message')}</div>
                                <CheckBox
                                    id="gdpr-info-collection-consent-check"
                                    checked={consentGiven}
                                    onChange={this.handleConsentGivenChange}
                                >
                                    <FormattedMessage
                                        message={uiStrings.getString('gdpr_collection_consent_message')}
                                        fields={[
                                            <a
                                                href={privacyPolicyLink}
                                                id="privacy-policy-link"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: `#${this.context.accentColor}` }}
                                            >
                                                {uiStrings.getString('privacy_policy')}
                                            </a>
                                        ]}
                                    />
                                </CheckBox>
                            </div>
                        )}
                        <Button
                            type="submit"
                            id="business-details-submit-btn"
                            disabled={!consentGiven}
                        >
                            {uiStrings.getString('submit')}
                        </Button>
                    </div>
                </form>
            </React.Fragment>
        );
    }

    render() {
        const { submitting } = this.state;
        const { accentColor } = this.props.company;

        return (
            <section className="business-details-step">
                {submitting ? renderSumbitting(accentColor) : this.renderForm() }
            </section>
        );
    }
}

BusinessDetailsStep.contextType = ThemeContext;

import React, { Component } from 'react';
import BusinessDetailsStep from './BusinessDetailsStep';
import CampaignDetailsStep from './CampaignDetailsStep';
import FacebookPageStep from './FacebookPageStep';
import FinishStep from './FinishStep';
import uiStrings from '../../services/uiStrings';
import './SignUpPage.scss';

class Step {
    constructor(title, component) {
        this.title = title;
        this.component = component;
    }
}

export default class SignUpPage extends Component {
    constructor(props) {
        super(props);

        const {
            businessDetailsPanel,
            facebookConnectPanel,
            campaignDetailsPanel,
            finishPanel
        } = props.company;
        const steps = [];

        if (businessDetailsPanel.enabled) steps.push(new Step(businessDetailsPanel.heading, BusinessDetailsStep));
        if (campaignDetailsPanel.enabled) steps.push(new Step(campaignDetailsPanel.heading, CampaignDetailsStep));
        if (facebookConnectPanel.enabled) steps.push(new Step(facebookConnectPanel.heading, FacebookPageStep));
        if (finishPanel.enabled) steps.push(new Step(finishPanel.heading, FinishStep));

        this.steps = steps;
        this.state = {
            currentStep: 0,
            customer: null
        };
    }

    componentDidMount() {
        // Using in a hope to prevent unintended refresh forcing user to start over resulting in dupe customer.
        window.addEventListener('beforeunload', this.handleReload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleReload);
    }

    onNextStep = (customer) => {
        if (this.state.currentStep === (this.steps.length - 1)) {
            this.props.onStartOver();
            return;
        }

        this.setState(prevState => ({ customer: customer || prevState.customer, currentStep: prevState.currentStep + 1 }));
    }

    handleReload = (event) => {
        const { currentStep } = this.state;
        const ContentTag = this.steps[currentStep].component;

        if (ContentTag !== FinishStep) {
            // eslint-disable-next-line no-param-reassign
            event.returnValue = false;
        }
    }

    renderStepBubbles() {
        const { currentStep } = this.state;

        const bubbles = this.steps.map((s, idx) => <span key={s.title} className={`bubble ${idx === currentStep ? 'active' : ''}`} />);

        return (
            <div className="step-bubbles">
                {bubbles}
            </div>
        );
    }

    render() {
        const { currentStep, customer } = this.state;
        const {
            company,
            defaultAddress,
            hasGoogleTagManagerIntegration,
            privacyPolicyLink
        } = this.props;
        const ContentTag = this.steps[currentStep].component;
        const btnStyle = {
            color: `#${company.accentColor}`
        };

        return (
            <div className="getting-started-page fadein">
                <ContentTag company={company} customer={customer} defaultAddress={defaultAddress} hasGoogleTagManagerIntegration={hasGoogleTagManagerIntegration} privacyPolicyLink={privacyPolicyLink} onNextStep={this.onNextStep} />
                <div className="step-footer">
                    <button type="button" className="text-btn" onClick={this.props.onStartOver} style={btnStyle}><i className="fas fa-chevron-left" /> {uiStrings.getString('start_new')}</button>
                    {this.renderStepBubbles()}
                </div>
            </div>
        );
    }
}

import axios from 'axios';
import utils from './utils';
import uiStrings from './uiStrings';

axios.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-CUSTOM-REQUEST-ID'] = utils.newUuid();
    return config;
});

axios.interceptors.response.use(
    response => response,
    (input) => {
        const { data } = input.response;
        const error = (data && data.error) || new Error(uiStrings.getString('unknown_error'));
        return Promise.reject(error);
    }
);

async function createCustomer(customer) {
    const response = await axios.post('api/customers', customer);
    return response.data.id;
}

async function patchCustomer(customerId, customer) {
    const customFields = JSON.parse(JSON.stringify(customer));
    delete customFields.website;

    let { website } = customer;
    if (website) website = website.startsWith('http') ? website : `https://${website}`;
    if (website === '') website = null;

    const requestData = {
        website,
        customFields
    };

    await axios.patch(`api/customers/${customerId}`, requestData);
    return requestData;
}

async function getReachEstimate(location) {
    const response = await axios.get('api/reach', { params: location });

    return response.data;
}

async function getCompanyDetails() {
    const response = await axios.get('api/company');
    return response.data;
}

async function getCustomFields() {
    const response = await axios.get('api/company/customFields');
    return response.data;
}

async function getPrivacyPolicy() {
    const response = await axios.get('api/company/privacyPolicy');
    return response.data;
}

async function getFooterMessage() {
    const response = await axios.get('api/company/footerMessage');
    return response.data;
}

function openCustomerOnboarding(customerId) {
    window.open(`api/customers/${customerId}/pageLink`, '_blank');
}

async function sendEmail(customer) {
    await axios.post('api/record/email', customer);
}

function downloadPdfRecord(customer) {
    const datastr = encodeURIComponent(JSON.stringify(customer));
    window.location.assign(`api/record/pdf?data=${datastr}`);
}

export default {
    getReachEstimate,
    getCompanyDetails,
    getCustomFields,
    createCustomer,
    patchCustomer,
    openCustomerOnboarding,
    sendEmail,
    downloadPdfRecord,
    getPrivacyPolicy,
    getFooterMessage
};

import React, { Component } from 'react';
import { Button, TextInput } from '@tigerpistol/react-core';
import api from '../../services/sutApi';
import uiStrings from '../../services/uiStrings';

const getInputId = fieldName => `${fieldName.toLowerCase().replace(' ', '')}-input`;

export default class CampaignDetailsStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: null,
            submitting: false,
            errorMessage: null,
            details: {}
        };
    }

    componentDidMount() {
        api.getCustomFields()
            .then((data) => {
                this.setState({ fields: data });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.setState({ submitting: true, errorMessage: null });

        try {
            const { customer } = this.props;
            const result = await api.patchCustomer(customer.id, this.state.details);
            customer.customFields = result.customFields;
            this.props.onNextStep(customer);
        } catch (err) {
            console.error(err);
            this.setState({ submitting: false, errorMessage: err.message });
        }
    }

    handleInputChange = (event, { newValue }) => {
        const { name } = event.target;

        this.setState((prevState) => {
            const { details } = prevState;

            details[name] = newValue;
            return { details };
        });
    }

    render() {
        const {
            details,
            fields,
            errorMessage,
            submitting
        } = this.state;
        const { campaignDetailsPanel } = this.props.company;
        const fieldNames = fields ? Object.keys(fields) : [];

        return (
            <section className="campaign-details-step">
                <h1 className="section-title">{campaignDetailsPanel.heading || uiStrings.getString('campaign_details_panel_heading')}</h1>
                <p className="section-message">{campaignDetailsPanel.message || uiStrings.getString('campaign_details_panel_text')}</p>
                {errorMessage ? <h4 className="alert red">{errorMessage}</h4> : null}

                <form onSubmit={this.onSubmit}>
                    {fieldNames.map(field => (
                        <TextInput
                            key={field}
                            name={field}
                            value={details[field] || ''}
                            onChange={this.handleInputChange}
                            placeholder={field}
                            id={getInputId(field)}
                        />
                    ))}
                    <div className="submit-btn-container">
                        <Button type="submit" loading={submitting} id="campaign-details-submit-btn">{uiStrings.getString('next')}</Button>
                    </div>
                </form>
            </section>
        );
    }
}
